@media (max-width: 700px) {
    
    footer {
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: default;
        background-color: #171920;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }

    footer .container-top {
        font-family: 'Lato', sans-serif;
        font-size: 16px;
        font-weight: 700;
        font-style: italic;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        color: #ffffff;
        padding: 20px 0;
        margin: 50px 0 0 0;
        width: 90%;
        height: auto;
    }

    footer .container-top .box {
        margin-bottom: 50px; 
    }

    footer .container-top h1 {
        text-align: center;
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        margin-bottom: 40px;
    }

    footer .container-top .box:nth-child(2) {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        font-style: normal;
    }

    footer .container-top .container-logo {
        width: auto; 
        height: auto; 
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 35px;
    }

    footer .container-top .container-logo img {
        width: 250px;
        height: auto;
        object-fit: contain;
    }

    footer .container-top .box:nth-child(1) p {
        max-width: 400px;
        text-align: center;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    footer .container-top .box:nth-child(2) {
        width: 230px;
    }

    footer .container-top .box:nth-child(2) span {
        text-align: center;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        margin-bottom: 5px;
        cursor: pointer;
    }

    footer .container-top .box:nth-child(3) {
        width: 230px;
        height: auto;
        font-style: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    footer .container-top .box:nth-child(3) .container-hours {
        width: 230px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 10px;
        font-weight: 400;
    }

    footer .container-top .box:nth-child(3) .container-hours .hours {
        width: 100px;
        display: flex;
        align-items: end;
        flex-direction: column;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    footer .container-top .box:nth-child(4) {
        width: 230px;
        height: auto;
        font-style: normal;
        display: flex;
        flex-direction: column;
    }

    footer .container-top .box:nth-child(4) div {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;
    }

    footer .container-top .box:nth-child(4) span {
        display: flex;
        text-align: start;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    footer .container-top .box:nth-child(4) div .icon-contact {
        width: 30px;
        font-size: 18px;
        margin-right: 5px;
    }

    footer .container-center {
        width: 90%;
        border-top: 1px solid #b8b8b893;
        padding: 20px;
        margin-top: 30px;
    }

    footer .container-center h5 {
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-style: italic;
        text-align: center;
        color: #ffffff;
    }

    footer .container-center h6 {
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-style: italic;
        text-align: center;
        color: #ffffff;
        margin-bottom: 15px;
    }

    footer .container-bottom {
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-style: italic;
        text-align: right;
        color: #ffffff;
        background-color: #000000;
        padding: 12.5px 20px;
        width: 100%;
    }
}

@media (min-width: 700px) and (max-width: 1200px) {
    
    footer {
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: default;
        background-color: #171920;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }

    footer .container-top {
        font-family: 'Lato', sans-serif;
        font-size: 16px;
        font-weight: 700;
        font-style: italic;
        text-align: center;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        color: #ffffff;
        padding: 20px 0;
        margin: 50px 0;
        width: 90%;
        height: auto;
    }

    footer .container-top .box {
        margin-bottom: 50px; 
    }

    footer .container-top h1 {
        text-align: start;
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        margin-bottom: 40px;
    }

    footer .container-top .box:nth-child(1),
    footer .container-top .box:nth-child(2) {
        width: 45%;
        height: auto;
        display: flex;
        flex-direction: column;
        font-style: normal;
    }

    footer .container-top .container-logo {
        width: auto; 
        height: auto; 
        display: flex;
        align-items: center;
        margin-bottom: 35px;
    }

    footer .container-top .container-logo img {
        width: 200px;
        height: auto;
        object-fit: contain;
    }

    footer .container-top .box:nth-child(1) p {
        width: 350px;
        text-align: start;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    footer .container-top .box:nth-child(2) {
        width: 230px;
    }

    footer .container-top .box:nth-child(2) span {
        text-align: start;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        margin-bottom: 5px;
        cursor: pointer;
    }

    footer .container-top .box:nth-child(3) {
        width: 45%;
        height: auto;
        font-style: normal;
        display: flex;
        flex-direction: column;
    }

    footer .container-top .box:nth-child(3) .container-hours {
        width: 200px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 10px;
        font-weight: 400;
    }

    footer .container-top .box:nth-child(3) .container-hours .hours {
        width: 100px;
        display: flex;
        align-items: start;
        flex-direction: column;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    footer .container-top .box:nth-child(4) {
        width: 230px;
        height: auto;
        font-style: normal;
        display: flex;
        flex-direction: column;
    }

    footer .container-top .box:nth-child(4) div {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;
    }

    footer .container-top .box:nth-child(4) span {
        display: flex;
        text-align: start;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    footer .container-top .box:nth-child(4) div .icon-contact {
        width: 30px;
        font-size: 18px;
        margin-right: 5px;
    }

    footer .container-center {
        position: relative;
        width: 90%;
        border-top: 1px solid #b8b8b893;
        padding: 20px;
        margin-top: 30px;
    }

    footer .container-center h5 {
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-style: italic;
        text-align: center;
        color: #ffffff;
    }

    footer .container-center h6 {
        position: absolute;
        right: 0;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-style: italic;
        text-align: center;
        color: #ffffff;
        margin-bottom: 10px;
    }

    footer .container-bottom {
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-style: italic;
        text-align: right;
        color: #ffffff;
        background-color: #000000;
        padding: 12.5px 20px;
        width: 100%;
    }
}

@media (min-width: 1200px) and (max-width: 1440px) {
    
    footer {
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: default;
        background-color: #171920;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }

    footer .container-top {
        font-family: 'Lato', sans-serif;
        font-size: 16px;
        font-weight: 700;
        font-style: italic;
        text-align: center;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        color: #ffffff;
        padding: 20px 0;
        margin: 50px 0;
        width: 90%;
        height: 300px;
    }

    footer .container-top h1 {
        text-align: start;
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        margin-bottom: 40px;
    }

    footer .container-top .box:nth-child(1) {
        width: 300px;
        height: auto;
        display: flex;
        flex-direction: column;
        font-style: normal;
    }

    footer .container-top .container-logo {
        width: auto; 
        height: auto; 
        display: flex;
        align-items: center;
        margin-bottom: 35px;
    }

    footer .container-top .container-logo img {
        width: 200px;
        height: auto;
        object-fit: contain;
    }

    footer .container-top .box:nth-child(1) p {
        text-align: start;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    footer .container-top .box:nth-child(2) {
        height: auto;
        font-style: normal;
        display: flex;
        flex-direction: column;
    }

    footer .container-top .box:nth-child(2) span {
        text-align: start;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        margin-bottom: 5px;
        cursor: pointer;
    }

    footer .container-top .box:nth-child(3) {
        font-style: normal;
        display: flex;
        flex-direction: column;
    }

    footer .container-top .box:nth-child(3) .container-hours {
        width: 200px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 10px;
        font-weight: 400;
    }

    footer .container-top .box:nth-child(3) .container-hours .hours {
        width: 100px;
        display: flex;
        align-items: start;
        flex-direction: column;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    footer .container-top .box:nth-child(4) {
        height: auto;
        font-style: normal;
        display: flex;
        flex-direction: column;
    }

    footer .container-top .box:nth-child(4) div {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;
    }

    footer .container-top .box:nth-child(4) span {
        display: flex;
        text-align: start;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    footer .container-top .box:nth-child(4) div .icon-contact {
        width: 30px;
        font-size: 18px;
        margin-right: 5px;
    }

    footer .container-center {
        position: relative;
        width: 90%;
        border-top: 1px solid #b8b8b893;
        padding: 20px;
        margin-top: 30px;
    }

    footer .container-center h5 {
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-style: italic;
        text-align: center;
        color: #ffffff;
    }

    footer .container-center h6 {
        position: absolute;
        right: 0;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-style: italic;
        text-align: center;
        color: #ffffff;
        margin-bottom: 10px;
    }

    footer .container-bottom {
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-style: italic;
        text-align: right;
        color: #ffffff;
        background-color: #000000;
        padding: 12.5px 20px;
        width: 100%;
    }
}

@media (min-width: 1440px) {

    footer {
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: default;
        background-color: #171920;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }

    footer .container-top {
        font-family: 'Lato', sans-serif;
        font-size: 16px;
        font-weight: 700;
        font-style: italic;
        text-align: center;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        color: #ffffff;
        padding: 20px 60px;
        margin: 50px 0;
        max-width: 1440px;
        width: 100%;
        height: 300px;
    }

    footer .container-top h1 {
        text-align: start;
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        margin-bottom: 40px;
    }

    footer .container-top .box:nth-child(1) {
        width: 300px;
        height: auto;
        display: flex;
        flex-direction: column;
        font-style: normal;
    }

    footer .container-top .container-logo {
        width: auto; 
        height: auto; 
        display: flex;
        align-items: center;
        margin-bottom: 35px;
    }

    footer .container-top .container-logo img {
        width: 200px;
        height: auto;
        object-fit: contain;
    }

    footer .container-top .box:nth-child(1) p {
        text-align: start;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    footer .container-top .box:nth-child(2) {
        height: auto;
        font-style: normal;
        display: flex;
        flex-direction: column;
    }

    footer .container-top .box:nth-child(2) span {
        text-align: start;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        margin-bottom: 5px;
        cursor: pointer;
    }

    footer .container-top .box:nth-child(3) {
        font-style: normal;
        display: flex;
        flex-direction: column;
    }

    footer .container-top .box:nth-child(3) .container-hours {
        width: 200px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 10px;
        font-weight: 400;
    }

    footer .container-top .box:nth-child(3) .container-hours .hours {
        width: 100px;
        display: flex;
        align-items: start;
        flex-direction: column;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    footer .container-top .box:nth-child(4) {
        height: auto;
        font-style: normal;
        display: flex;
        flex-direction: column;
    }

    footer .container-top .box:nth-child(4) div {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;
    }

    footer .container-top .box:nth-child(4) span {
        display: flex;
        text-align: start;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    footer .container-top .box:nth-child(4) div .icon-contact {
        width: 30px;
        font-size: 18px;
        margin-right: 5px;
    }

    footer .container-center {
        position: relative;
        border-top: 1px solid #b8b8b893;
        padding: 20px;
        margin-top: 30px;
        max-width: 1320px;
        width: 100%;
    }

    footer .container-center h5 {
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-style: italic;
        text-align: center;
        color: #ffffff;
    }

    footer .container-center h6 {
        position: absolute;
        right: 0;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-style: italic;
        text-align: center;
        color: #ffffff;
        margin-bottom: 10px;
    }

    footer .container-bottom {
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-style: italic;
        text-align: right;
        color: #ffffff;
        background-color: #000000;
        padding: 12.5px 20px;
        width: 100%;
    }
}