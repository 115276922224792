.background-history {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    margin: 0 auto;
    position: relative; 
    color: #ffffff;
}

@media (max-width: 600px) {
    
    .background-history .container-history-1 {
        padding: 25px 0px 20px 0;
        margin: 20px 0 10px 0;
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .background-history .container-history-1 .container-img {
        width: 100%;
        height: 100%;
        margin: 0 auto 50px auto;
    }
    
    .background-history .container-history-1 .container-img img {
        width: 100%;
        height: 100%;
        border: 5px solid #ffffff;
        object-fit: cover;
    }
    
    .background-history .container-history-1 .container-text {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
    }
    
    .background-history .container-history-1 .container-text h1 {
        font-size: 25px;
        text-align: left;
        margin-bottom: 30px;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
    }
    
    .background-history .container-history-1 .container-text p {
        font-size: 15px;
        text-align: justify;
        font-family: "Poppins", sans-serif;
        font-weight: 300;
    }
    
    .background-history .container-history-2 {
        padding: 20px 0px 50px 0px;
        margin: 20px auto 80px auto;
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .background-history .container-history-2 h2 {
        text-align: left;
        font-size: 25px;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        margin-bottom: 50px;
    }
    
    .background-history .container-history-2 .container-img {
        width: 100%;
    }
    
    .background-history .container-history-2 .container-img .slick-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px; 
    }
    
    .background-history .container-history-2 .container-img img {
        width: 100%;
        height: 90%;
        object-fit: cover;
        border: 3px solid #ffffff;
    }
    
}

@media (min-width: 600px) and (max-width: 1000px) {
    
    .background-history .container-history-1 {
        padding: 50px 0px 20px 0;
        margin: 40px 0 10px 0;
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .background-history .container-history-1 .container-img {
        width: 100%;
        height: 100%;
        margin: 0 auto 50px auto;
    }
    
    .background-history .container-history-1 .container-img img {
        width: 100%;
        height: 100%;
        border: 5px solid #ffffff;
        object-fit: cover;
    }
    
    .background-history .container-history-1 .container-text {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
    }
    
    .background-history .container-history-1 .container-text h1 {
        font-size: 28px;
        margin-bottom: 30px;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
    }
    
    .background-history .container-history-1 .container-text p {
        font-size: 15px;
        text-align: justify;
        font-family: "Poppins", sans-serif;
        font-weight: 300;
    }
    
    .background-history .container-history-2 {
        padding: 20px 0px 50px 0px;
        margin: 20px auto 80px auto;
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .background-history .container-history-2 h2 {
        text-align: center;
        font-size: 28px;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        margin-bottom: 50px;
    }
    
    .background-history .container-history-2 .container-img {
        width: 100%;
    }
    
    .background-history .container-history-2 .container-img .slick-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px; 
    }
    
    .background-history .container-history-2 .container-img img {
        width: 100%;
        height: 90%;
        object-fit: cover;
        border: 3px solid #ffffff;
    }
    
}

@media (min-width: 1000px) and (max-width: 1100px) {
    
    .background-history .container-history-1 {
        padding: 50px 0px 20px 0;
        margin: 40px 0 10px 0;
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .background-history .container-history-1 .container-img {
        width: 100%;
        height: 100%;
        margin: 0 auto 50px auto;
    }
    
    .background-history .container-history-1 .container-img img {
        width: 100%;
        height: 100%;
        border: 5px solid #ffffff;
        object-fit: cover;
    }
    
    .background-history .container-history-1 .container-text {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
    }
    
    .background-history .container-history-1 .container-text h1 {
        font-size: 28px;
        margin-bottom: 30px;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
    }
    
    .background-history .container-history-1 .container-text p {
        font-size: 15px;
        margin-bottom: 30px;
        font-family: "Poppins", sans-serif;
        font-weight: 300;
    }
    
    .background-history .container-history-2 {
        padding: 0px 0px 50px 0px;
        margin: 0px auto 80px auto;
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .background-history .container-history-2 h2 {
        text-align: center;
        font-size: 28px;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        margin-bottom: 50px;
    }
    
    .background-history .container-history-2 .container-img {
        width: 100%;
    }
    
    .background-history .container-history-2 .container-img .slick-slide {
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding: 0 10px; 
    }
    
    .background-history .container-history-2 .container-img img {
        width: 100%;
        height: 90%;
        object-fit: cover;
        border: 3px solid #ffffff;
    }
    
}


@media (min-width: 1100px) and (max-width: 1440px) {

    .background-history .container-history-1 {
        max-width: 1100px;
        padding: 50px 60px;
        margin: 4vw 0 1vw 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    
    .background-history .container-history-1 .container-img {
        width: 39%;
        height: 100%;
    }
    
    .background-history .container-history-1 .container-img img {
        width: 100%;
        height: 100%;
        border: 5px solid #ffffff;
        object-fit: cover;
    }
    
    .background-history .container-history-1 .container-text {
        width: 59%;
        height: 100%;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
    }
    
    .background-history .container-history-1 .container-text h1 {
        font-size: 28px;
        margin-bottom: 30px;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
    }
    
    .background-history .container-history-1 .container-text p {
        font-size: 15px;
        margin-bottom: 30px;
        font-family: "Poppins", sans-serif;
        font-weight: 300;
    }
    
    .background-history .container-history-2 {
        max-width: 1100px;
        padding: 50px 60px;
        margin: 1vw 0 8vw 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .background-history .container-history-2 h2 {
        text-align: center;
        font-size: 28px;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        margin-bottom: 50px;
    }
    
    .background-history .container-history-2 .container-img {
        width: 100%;
    }
    
    .background-history .container-history-2 .container-img .slick-slide {
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding: 0 10px; 
    }
    
    .background-history .container-history-2 .container-img img {
        width: 100%;
        height: 420px;
        object-fit: cover;
        border: 3px solid #ffffff;
    }
    
}

@media (min-width: 1440px) {

    .background-history .container-history-1 {
        max-width: 1440px;
        padding: 50px 60px;
        margin: 4vw 0 1vw 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    
    .background-history .container-history-1 .container-img {
        width: 39%;
        height: 100%;
    }
    
    .background-history .container-history-1 .container-img img {
        width: 100%;
        height: 100%;
        border: 5px solid #ffffff;
        object-fit: cover;
    }
    
    .background-history .container-history-1 .container-text {
        width: 59%;
        height: 100%;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
    }
    
    .background-history .container-history-1 .container-text h1 {
        font-size: 28px;
        margin-bottom: 30px;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
    }
    
    .background-history .container-history-1 .container-text p {
        font-size: 15px;
        margin-bottom: 30px;
        font-family: "Poppins", sans-serif;
        font-weight: 300;
    }
    
    .background-history .container-history-2 {
        max-width: 1440px;
        padding: 50px 60px;
        margin: 1vw 0 8vw 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .background-history .container-history-2 h2 {
        text-align: center;
        font-size: 28px;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        margin-bottom: 50px;
    }
    
    .background-history .container-history-2 .container-img {
        width: 100%;
    }
    
    .background-history .container-history-2 .container-img .slick-slide {
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding: 0 10px; 
    }
    
    .background-history .container-history-2 .container-img img {
        width: 100%;
        height: 420px;
        object-fit: cover;
        border: 3px solid #ffffff;
    }
    
}
