.background-home {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    margin: 0 auto;
    position: relative; 
}

@media (max-width: 600px) {

    .background-home .container-home-1 {
        width: 100%;
        height: 50vh; 
        position: relative;
    }

    .background-home .container-home-1 img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-bottom: 2px solid #ececec23;
    }

    .background-home .container-home-1 .container-text {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%; 
        left: 50%;
        transform: translate(-50%, -50%); 
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        background-color: #15375288;
        z-index: 2;
    }

    .background-home .container-home-1 .container-text h2 {
        font-size: 5.5vw;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-style: normal;  
        line-height: 1; 
    }

    .background-home .container-home-1 .container-text h3 {
        font-size: 16vw;
        font-family: "Merriweather", serif;
        font-weight: 400;
        font-style: normal;  
    }

    .background-home .container-home-1 .container-text h1 {
        font-size: 5.2vw;
        font-family: "Merriweather", serif;
        font-weight: 500;
        font-style: normal;  
        line-height: .4; 
    }

    .background-home .container-home-2 {
        width: 100%;
        padding: 50px 20px;
    }

    .background-home .container-home-2 .slick-slide {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background-home .container-home-2 .box {
        width: 100%;
        height: auto;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .background-home .container-home-2 .box .container-icon {
        width: 100%;
        margin-bottom: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background-home .container-home-2 .box .container-icon .icon {
        font-size: 70px;
    }

    .background-home .container-home-2 .box .container-title {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
    }

    .background-home .container-home-2 .box .container-title h4 {
        font-size: 18px;
    }

    .background-home .container-home-2 .box .container-description {
        width: 100%;
        height: 30%;
        margin-bottom: 20px;
        text-align: center;
    }

    .background-home .container-home-2 .box .container-description p {
        font-size: 14px;
        font-family: "Poppins", sans-serif;
        font-weight: 200;
    }

    .background-home .container-home-2 .box .container-btn {
        width: 100%;
        height: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background-home .container-home-2 .box .container-btn button {
        font-size: 14px;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        padding: 6px 20px;
        border-radius: 30px;
        background-color: #2e6fa5;
        color: #ffffff;
        border: none;
        cursor: pointer;
    }

    .background-home .container-home-2 .box .container-btn button:hover {
        background-color: #266497;
    }

    @keyframes swing {
        0% { transform: rotate(-20deg); }
        50% { transform: rotate(20deg); }
        100% { transform: rotate(-20deg); }
    }

    .container-home-3 {
        max-width: 1440px;
        width: 100%;
        padding: 0 20px;
        display: flex; 
        align-items: center;
        text-align: center; 
        color: #ffffff;
        font-weight: 500;
        font-size: 250%;
        margin: 4vw 0;
    }

    .container-home-3::before,
    .container-home-3::after {
        content: ''; 
        flex: 1; 
        height: 1px; 
        background: #ffffff;
        margin: 0 10px;
    }

    .container-home-3 .fa-anchor {
        animation: swing 1.5s ease-in-out infinite;
    }
}

@media (min-width: 600px) and (max-width: 1000px) {

    .background-home .container-home-1 {
        width: 100%;
        height: 55vh; 
        position: relative;
    }

    .background-home .container-home-1 img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-bottom: 2px solid #ececec23;
    }

    .background-home .container-home-1 .container-text {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%; 
        left: 50%;
        transform: translate(-50%, -50%); 
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        background-color: #15375288;
        z-index: 2;
    }

    .background-home .container-home-1 .container-text h2 {
        font-size: 2.8vw;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-style: normal;  
        line-height: 1; 
    }

    .background-home .container-home-1 .container-text h3 {
        font-size: 12vw;
        font-family: "Merriweather", serif;
        font-weight: 400;
        font-style: normal;  
    }

    .background-home .container-home-1 .container-text h1 {
        font-size: 2.8vw;
        font-family: "Merriweather", serif;
        font-weight: 500;
        font-style: normal;  
        line-height: .1; 
    }

    .background-home .container-home-2 {
        width: 100%;
        padding: 50px 20px;
    }

    .background-home .container-home-2 .slick-slide {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background-home .container-home-2 .box {
        width: 100%;
        height: auto;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .background-home .container-home-2 .box .container-icon {
        width: 100%;
        margin-bottom: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background-home .container-home-2 .box .container-icon .icon {
        font-size: 70px;
    }

    .background-home .container-home-2 .box .container-title {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
    }

    .background-home .container-home-2 .box .container-title h4 {
        font-size: 18px;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
    }

    .background-home .container-home-2 .box .container-description {
        width: 100%;
        height: 30%;
        margin-bottom: 20px;
        text-align: center;
    }

    .background-home .container-home-2 .box .container-description p {
        font-size: 14px;
        font-family: "Poppins", sans-serif;
        font-weight: 200;
    }

    .background-home .container-home-2 .box .container-btn {
        width: 100%;
        height: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background-home .container-home-2 .box .container-btn button {
        font-size: 14px;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        padding: 6px 20px;
        border-radius: 30px;
        background-color: #2e6fa5;
        color: #ffffff;
        border: none;
        cursor: pointer;
    }

    .background-home .container-home-2 .box .container-btn button:hover {
        background-color: #266497;
    }

    @keyframes swing {
        0% { transform: rotate(-20deg); }
        50% { transform: rotate(20deg); }
        100% { transform: rotate(-20deg); }
    }

    .container-home-3 {
        max-width: 1440px;
        width: 100%;
        padding: 0 5%;
        display: flex; 
        align-items: center;
        text-align: center; 
        color: #ffffff;
        font-weight: 500;
        font-size: 250%;
        margin: 4vw 0;
    }

    .container-home-3::before,
    .container-home-3::after {
        content: ''; 
        flex: 1; 
        height: 1px; 
        background: #ffffff;
        margin: 0 10px;
    }

    .container-home-3 .fa-anchor {
        animation: swing 1.5s ease-in-out infinite;
    }
}

@media (min-width: 1000px) and (max-width: 1440px) {

    .background-home .container-home-1 {
        width: 100%;
        height: 55vh; 
        position: relative;
    }

    .background-home .container-home-1 img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-bottom: 2px solid #ececec23;
    }

    .background-home .container-home-1 .container-text {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%; 
        left: 50%;
        transform: translate(-50%, -50%); 
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        background-color: #15375288;
        z-index: 2;
    }

    .background-home .container-home-1 .container-text h2 {
        font-size: 2vw;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-style: normal;  
        line-height: 1; 
    }

    .background-home .container-home-1 .container-text h3 {
        font-size: 8vw;
        font-family: "Merriweather", serif;
        font-weight: 400;
        font-style: normal;  
    }

    .background-home .container-home-1 .container-text h1 {
        font-size: 2vw;
        font-family: "Merriweather", serif;
        font-weight: 500;
        font-style: normal;  
        line-height: .1; 
    }

    .background-home .container-home-2 {
        width: 100%;
        padding: 50px 20px;
    }

    .background-home .container-home-2 .slick-slide {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background-home .container-home-2 .box {
        width: 90%;
        height: auto;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .background-home .container-home-2 .box .container-icon {
        width: 100%;
        margin-bottom: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background-home .container-home-2 .box .container-icon .icon {
        font-size: 70px;
    }

    .background-home .container-home-2 .box .container-title {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
    }

    .background-home .container-home-2 .box .container-title h4 {
        font-size: 18px;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
    }

    .background-home .container-home-2 .box .container-description {
        width: 100%;
        height: 30%;
        margin-bottom: 20px;
        text-align: center;
    }

    .background-home .container-home-2 .box .container-description p {
        font-size: 14px;
        font-family: "Poppins", sans-serif;
        font-weight: 200;
    }

    .background-home .container-home-2 .box .container-btn {
        width: 100%;
        height: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background-home .container-home-2 .box .container-btn button {
        font-size: 14px;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        padding: 6px 20px;
        border-radius: 30px;
        background-color: #2e6fa5;
        color: #ffffff;
        border: none;
        cursor: pointer;
    }

    .background-home .container-home-2 .box .container-btn button:hover {
        background-color: #266497;
    }

    @keyframes swing {
        0% { transform: rotate(-20deg); }
        50% { transform: rotate(20deg); }
        100% { transform: rotate(-20deg); }
    }

    .container-home-3 {
        max-width: 1440px;
        width: 100%;
        padding: 0 60px;
        display: flex; 
        align-items: center;
        text-align: center; 
        color: #ffffff;
        font-weight: 500;
        font-size: 250%;
        margin: 4vw 0;
    }

    .container-home-3::before,
    .container-home-3::after {
        content: ''; 
        flex: 1; 
        height: 1px; 
        background: #ffffff;
        margin: 0 10px;
    }

    .container-home-3 .fa-anchor {
        animation: swing 1.5s ease-in-out infinite;
    }
}

@media (min-width: 1440px) {

    .background-home .container-home-1 {
        width: 100%;
        height: 60vh; 
        position: relative;
    }

    .background-home .container-home-1 img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-bottom: 2px solid #ececec23;
    }

    .background-home .container-home-1 .container-text {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%; 
        left: 50%;
        transform: translate(-50%, -50%); 
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        background-color: #15375288;
        z-index: 2;
    }

    .background-home .container-home-1 .container-text h2 {
        font-size: 1.2vw;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-style: normal;  
        line-height: 1; 
    }

    .background-home .container-home-1 .container-text h3 {
        font-size: 5vw;
        font-family: "Merriweather", serif;
        font-weight: 400;
        font-style: normal;  
    }

    .background-home .container-home-1 .container-text h1 {
        font-size: 1.2vw;
        font-family: "Merriweather", serif;
        font-weight: 500;
        font-style: normal;  
        line-height: .1; 
    }

    .background-home .container-home-2 {
        max-width: 1440px;
        padding: 50px 60px;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .background-home .container-home-2 .box {
        width: 22%;
        height: auto;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .background-home .container-home-2 .box .container-icon {
        width: 100%;
        margin-bottom: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background-home .container-home-2 .box .container-icon .icon {
        font-size: 80px;
    }

    .background-home .container-home-2 .box .container-title {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
    }

    .background-home .container-home-2 .box .container-title h4 {
        font-size: 18px;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
    }

    .background-home .container-home-2 .box .container-description {
        width: 100%;
        height: 30%;
        margin-bottom: 20px;
        text-align: center;
    }

    .background-home .container-home-2 .box .container-description p {
        font-size: 15px;
        font-family: "Poppins", sans-serif;
        font-weight: 200;
    }

    .background-home .container-home-2 .box .container-btn {
        width: 100%;
        height: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background-home .container-home-2 .box .container-btn button {
        font-size: 15px;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        padding: 6px 20px;
        border-radius: 30px;
        background-color: #2e6fa5;
        color: #ffffff;
        border: none;
        cursor: pointer;
    }

    .background-home .container-home-2 .box .container-btn button:hover {
        background-color: #266497;
    }

    @keyframes swing {
        0% { transform: rotate(-20deg); }
        50% { transform: rotate(20deg); }
        100% { transform: rotate(-20deg); }
    }

    .container-home-3 {
        max-width: 1440px;
        width: 100%;
        padding: 0 60px;
        display: flex; 
        align-items: center;
        text-align: center; 
        color: #ffffff;
        font-weight: 500;
        font-size: 250%;
        margin: 4vw 0;
    }

    .container-home-3::before,
    .container-home-3::after {
        content: ''; 
        flex: 1; 
        height: 1px; 
        background: #ffffff;
        margin: 0 10px;
    }

    .container-home-3 .fa-anchor {
        animation: swing 1.5s ease-in-out infinite;
    }
}

