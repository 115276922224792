/* Header.css */
header.header-history {
    background-color: transparent;
}

@media (max-width: 900px) {

    header {
        width: 100%;
        height: 70px;
        color: #ffffff;
        background-color: #1b3852;
        font-family: 'Poppins', sans-serif;
        font-weight: 700;
        z-index: 9999;
        box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    }    
    
    .no-scroll-header {
        overflow: hidden;
        height: 100%;
        position: fixed;
        width: 100%;
    }

    header ul {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: end;
        flex-direction: row;
    }

    header ul .container-logo {
        position: absolute;
        left: 10px;
        width: auto; 
        height: auto; 
        display: flex;
        align-items: center;
    }
    
    header ul .container-logo img {
        width: 160px;
        height: auto;
        object-fit: contain;
    }
    
    header ul .menu-icon {
        font-size: 170%;
        cursor: pointer;
    }

    header .container-nav {
        position: absolute;
        top: 0%;
        right: -100%;
        width: 100%;
        padding: 0 0 20px 0;
        height: auto;
        display: flex;
        flex-direction: column;
        background-color: #1b3852;
        z-index: 9999;
        transition: right 0.2s ease-in-out; 
        overflow-y: none;
    }
    
    header .container-nav.visible {
        right: 0;
    }

    header .container-nav .container-close-icon {
        width: 100%;
        height: 70px;
        padding: 0 20px;
        font-size: 170%;
        margin-bottom: 20px;
        display: flex;
        justify-content: end;
        align-items: center;
        cursor: pointer;
        box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    }

    header ul li {
        width: 100%;
        list-style: none;
        margin: 15px auto;
        font-size: 110%;
        text-align: center;
        cursor: pointer;
    }

    header ul li .link {
        text-decoration: none;
        color: #ffffff;
    }

    header ul p {
        margin-left: 40%;
        width: 60%;
        font-size: 80%;
        margin: 15px auto;
        font-weight: 300;
        text-align: center;
    }
}

@media (min-width: 900px) {

    header {
        width: 100%;
        height: 70px;
        color: #333;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        color: #ffffff;
        background-color: #1b3852;
        z-index: 10;
        box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    }

    header ul {
        position: relative;
        max-width: 1440px;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        padding: 0 60px;
        display: flex;
        align-items: center;
        justify-content: end;
        flex-direction: row;
    }

    header ul .menu-icon {
        display: none;
    }

    header ul .container-nav .container-close-icon {
        display: none;
    }

    header ul .container-logo {
        position: absolute;
        left: 60px;
        width: auto; 
        height: auto; 
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    
    header ul .container-logo img {
        width: 230px;
        height: auto;
        object-fit: contain;
    }

    header .container-nav {
        position: relative;
        width: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    header ul li {
        position: relative;
        list-style: none;
        margin: 0 15px;
        font-size: 14px;
        cursor: pointer;
    }

    header ul li .link {
        text-decoration: none;
        color: #ffffff;
    }
    
    header ul li::after {
        content: '';
        position: absolute;
        width: 0;
        height: 2px;
        display: block;
        left: 0;
        bottom: -5px;
        background: #ffffff;
        transition: width 0.4s ease; 
    }
    
    header ul li:hover::after {
        width: 100%;
    }
    
    header .dialog-box {
        position: absolute;
        top: 35px;
        left: 0;
        width: 250px;
        background-color: white;
        border: 1px solid #cfcfcf;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 10px;
        z-index: 10;
    }
    
    header .dialog-box p {
        margin: 5px 0;
        font-size: 14px;
        color: #333;
    }
    
    header .dialog-box p:hover {
        color: rgb(161, 161, 161);
    }
}