.grecaptcha-badge {
    visibility: hidden;
}

@media (max-width: 600px) {
    
    .container-contact {
        width: 100%;
        padding: 50px 20px;
        max-width: 1440px;
        height: auto;
        margin: 0 auto 10vw auto;
        color: #ffffff;
        display: flex;
        flex-direction: column;
    }

    .container-contact .container-left {
        width: 100%;
        height: auto;
        font-family: 'Poppins', sans-serif;
    }

    .container-contact .container-left h1 {
        font-size: 28px;
        font-weight: 500;
        text-align: center;
    }

    .container-contact .container-left p {
        font-size: 15px;
        font-weight: 300;
        text-align: center;
        margin-bottom: 30px;
    }

    .container-contact .container-left label {
        font-size: 14px;
        font-weight: 600;
    }

    .container-contact .container-left input {
        width: 100%;
        height: 35px;
        padding: 10px;
        font-size: 14px;
        font-weight: 400;
        font-family: 'Poppins', sans-serif;
        outline: none;
        color: #ffffff;
        border: 1px solid #ffffff85;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.089);
    }

    .container-contact .container-left select {
        appearance: none; 
        -webkit-appearance: none; 
        -moz-appearance: none; 
        width: 100%;
        height: 35px;
        padding: 0 8px;
        margin: 0 0 .5vw 0;
        font-size: 14px;
        font-weight: 400;
        font-family: 'Poppins', sans-serif;
        outline: none;
        color: #ffffff;
        border: 1px solid #ffffff85;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.089);
    }

    .container-contact .container-left option {
        color: #333;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
    }

    .container-contact .container-left textarea {
        width: 100%;
        height: 120px;
        padding: 10px;
        font-size: 15px;
        font-weight: 400;
        font-family: 'Poppins', sans-serif;
        outline: none;
        color: #ffffff;
        border: 1px solid #ffffff85;
        border-radius: 4px;
        resize: none;
        background-color: rgba(255, 255, 255, 0.089);
    }

    .container-contact .container-left .container-1 {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
    }

    .container-contact .container-left .container-1 .input-box {
        width: 100%;
        margin: 5px 0;
        height: auto;
        display: flex;
        flex-direction: column;
    }

    .container-contact .container-left .container-2 {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
    }

    .container-contact .container-left .container-2 .input-box {
        width: 100%;
        margin: 5px 0;
        height: auto;
        display: flex;
        flex-direction: column;
    }

    .container-contact .container-left .container-3 {
        width: 100%;
        height: auto;
        margin: 30px 0;
        display: flex;
        flex-direction: column;
    }

    .container-contact .container-left .container-3 .input-box {
        width: 100%;
        margin: 5px 0;
        height: auto;
        display: flex;
        flex-direction: column;
    }

    .container-contact .container-left .container-btn {
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: end;
        align-items: center;
    }

    .container-contact .container-left .container-btn button {
        font-size: 14px;
        font-weight: 600;
        padding: 10px 25px;
        background-color: #2e6fa5;
        color: #ffffff;
        border: none;
        border-radius: 6px;
        cursor: pointer;
    }

    .container-contact .container-left .container-btn button:hover {
        background-color: #266497;
    }

    .container-right {
        display: none;
    }

    .container-right img {
        width: 70%;
        height: auto;
        object-fit: contain;
    }
}

@media (min-width: 600px) and (max-width: 1000px) {
    
    .container-contact {
        width: 100%;
        padding: 50px 5%;
        max-width: 1440px;
        height: auto;
        margin: 0 auto 10vw auto;
        color: #ffffff;
        display: flex;
        flex-direction: row;
    }

    .container-contact .container-left {
        width: 100%;
        height: auto;
        font-family: 'Poppins', sans-serif;
    }

    .container-contact .container-left h1 {
        font-size: 28px;
        font-weight: 500;
    }

    .container-contact .container-left p {
        width: 75%;
        font-size: 15px;
        font-weight: 300;
        margin-bottom: 2vw;
    }

    .container-contact .container-left label {
        font-size: 14px;
        font-weight: 600;
    }

    .container-contact .container-left input {
        width: 100%;
        height: 35px;
        padding: 10px;
        font-size: 14px;
        font-weight: 400;
        font-family: 'Poppins', sans-serif;
        outline: none;
        color: #ffffff;
        border: 1px solid #ffffff85;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.089);
    }

    .container-contact .container-left select {
        appearance: none; 
        -webkit-appearance: none; 
        -moz-appearance: none; 
        width: 49%;
        height: 35px;
        padding: 0 8px;
        margin: 0 0 .5vw 0;
        font-size: 14px;
        font-weight: 400;
        font-family: 'Poppins', sans-serif;
        outline: none;
        color: #ffffff;
        border: 1px solid #ffffff85;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.089);
    }

    .container-contact .container-left option {
        color: #333;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
    }

    .container-contact .container-left textarea {
        width: 100%;
        height: 120px;
        padding: 10px;
        font-size: 15px;
        font-weight: 400;
        font-family: 'Poppins', sans-serif;
        outline: none;
        color: #ffffff;
        border: 1px solid #ffffff85;
        border-radius: 4px;
        resize: none;
        background-color: rgba(255, 255, 255, 0.089);
    }

    .container-contact .container-left .container-1 {
        width: 100%;
        height: auto;
        margin: .5vw 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .container-contact .container-left .container-1 .input-box {
        display: flex;
        flex-direction: column;
        width: 49%;
        height: auto;
    }

    .container-contact .container-left .container-2 {
        width: 100%;
        height: auto;
        margin: .5vw 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .container-contact .container-left .container-2 .input-box {
        display: flex;
        flex-direction: column;
        width: 49%;
        height: auto;
    }

    .container-contact .container-left .container-3 {
        width: 100%;
        height: auto;
        margin: 1vw 0;
        display: flex;
        flex-direction: column;
    }

    .container-contact .container-left .container-3 .input-box {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
    }

    .container-contact .container-left .container-btn {
        width: 100%;
        margin-top: 2vw;
        display: flex;
        justify-content: end;
        align-items: center;
    }

    .container-contact .container-left .container-btn button {
        font-size: 14px;
        font-weight: 600;
        padding: 10px 25px;
        background-color: #2e6fa5;
        color: #ffffff;
        border: none;
        border-radius: 6px;
        cursor: pointer;
    }

    .container-contact .container-left .container-btn button:hover {
        background-color: #266497;
    }

    .container-right {
        display: none;
    }

    .container-right img {
        width: 70%;
        height: auto;
        object-fit: contain;
    }
}

@media (min-width: 1000px) and (max-width: 1440px) {
    
    .container-contact {
        width: 90%;
        padding: 20px 0px 50px 0px;
        height: auto;
        margin: 20px auto 80px auto;
        color: #ffffff;
        display: flex;
        flex-direction: row;
    }

    .container-contact .container-left {
        width: 70%;
        height: auto;
        font-family: 'Poppins', sans-serif;
    }

    .container-contact .container-left h1 {
        font-size: 28px;
        font-weight: 500;
    }

    .container-contact .container-left p {
        width: 75%;
        font-size: 15px;
        font-weight: 300;
        margin-bottom: 2vw;
    }

    .container-contact .container-left label {
        font-size: 14px;
        font-weight: 600;
    }

    .container-contact .container-left input {
        width: 100%;
        height: 35px;
        padding: 10px;
        font-size: 14px;
        font-weight: 400;
        font-family: 'Poppins', sans-serif;
        outline: none;
        color: #ffffff;
        border: 1px solid #ffffff85;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.089);
    }

    .container-contact .container-left select {
        appearance: none; 
        -webkit-appearance: none; 
        -moz-appearance: none; 
        width: 49%;
        height: 35px;
        padding: 0 8px;
        margin: 0 0 .5vw 0;
        font-size: 14px;
        font-weight: 400;
        font-family: 'Poppins', sans-serif;
        outline: none;
        color: #ffffff;
        border: 1px solid #ffffff85;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.089);
    }

    .container-contact .container-left option {
        color: #333;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
    }

    .container-contact .container-left textarea {
        width: 100%;
        height: 120px;
        padding: 10px;
        font-size: 15px;
        font-weight: 400;
        font-family: 'Poppins', sans-serif;
        outline: none;
        color: #ffffff;
        border: 1px solid #ffffff85;
        border-radius: 4px;
        resize: none;
        background-color: rgba(255, 255, 255, 0.089);
    }

    .container-contact .container-left .container-1 {
        width: 100%;
        height: auto;
        margin: .5vw 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .container-contact .container-left .container-1 .input-box {
        display: flex;
        flex-direction: column;
        width: 49%;
        height: auto;
    }

    .container-contact .container-left .container-2 {
        width: 100%;
        height: auto;
        margin: .5vw 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .container-contact .container-left .container-2 .input-box {
        display: flex;
        flex-direction: column;
        width: 49%;
        height: auto;
    }

    .container-contact .container-left .container-3 {
        width: 100%;
        height: auto;
        margin: 1vw 0;
        display: flex;
        flex-direction: column;
    }

    .container-contact .container-left .container-3 .input-box {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
    }

    .container-contact .container-left .container-btn {
        width: 100%;
        margin-top: 2vw;
        display: flex;
        justify-content: end;
        align-items: center;
    }

    .container-contact .container-left .container-btn button {
        font-size: 14px;
        font-weight: 600;
        padding: 10px 25px;
        background-color: #2e6fa5;
        color: #ffffff;
        border: none;
        border-radius: 6px;
        cursor: pointer;
    }

    .container-contact .container-left .container-btn button:hover {
        background-color: #266497;
    }

    .container-right {
        width: 50%;
        height: auto;
        display: flex;
        justify-content: end;
        align-content: center;
    }

    .container-right img {
        width: 70%;
        height: auto;
        object-fit: contain;
    }
}

@media (min-width: 1440px) {

    .container-contact {
        width: 100%;
        padding: 50px 60px;
        max-width: 1440px;
        height: auto;
        margin: 0 auto 8vw auto;
        color: #ffffff;
        display: flex;
        flex-direction: row;
    }

    .container-contact .container-left {
        width: 50%;
        height: auto;
        font-family: 'Poppins', sans-serif;
    }

    .container-contact .container-left h1 {
        font-size: 30px;
        font-weight: 500;
    }

    .container-contact .container-left p {
        width: 65%;
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 1.5vw;
    }

    .container-contact .container-left label {
        font-size: 15px;
        font-weight: 600;
    }

    .container-contact .container-left input {
        width: 100%;
        height: 35px;
        padding: 10px;
        font-size: 14px;
        font-weight: 400;
        font-family: 'Poppins', sans-serif;
        outline: none;
        color: #ffffff;
        border: 1px solid #ffffff85;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.089);
    }

    .container-contact .container-left select {
        appearance: none; 
        -webkit-appearance: none; 
        -moz-appearance: none; 
        width: 49%;
        height: 35px;
        padding: 0 8px;
        margin: 0 0 .5vw 0;
        font-size: 14px;
        font-weight: 400;
        font-family: 'Poppins', sans-serif;
        outline: none;
        color: #ffffff;
        border: 1px solid #ffffff85;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.089);
    }

    .container-contact .container-left option {
        color: #333;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
    }

    .container-contact .container-left textarea {
        width: 100%;
        height: 120px;
        padding: 10px;
        font-size: 15px;
        font-weight: 400;
        font-family: 'Poppins', sans-serif;
        outline: none;
        color: #ffffff;
        border: 1px solid #ffffff85;
        border-radius: 4px;
        resize: none;
        background-color: rgba(255, 255, 255, 0.089);
    }

    .container-contact .container-left .container-1 {
        width: 100%;
        height: auto;
        margin: .5vw 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .container-contact .container-left .container-1 .input-box {
        display: flex;
        flex-direction: column;
        width: 49%;
        height: auto;
    }

    .container-contact .container-left .container-2 {
        width: 100%;
        height: auto;
        margin: .5vw 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .container-contact .container-left .container-2 .input-box {
        display: flex;
        flex-direction: column;
        width: 49%;
        height: auto;
    }

    .container-contact .container-left .container-3 {
        width: 100%;
        height: auto;
        margin: 1vw 0;
        display: flex;
        flex-direction: column;
    }

    .container-contact .container-left .container-3 .input-box {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
    }

    .container-contact .container-left .container-btn {
        width: 100%;
        margin-top: 2vw;
        display: flex;
        justify-content: end;
        align-items: center;
    }

    .container-contact .container-left .container-btn button {
        font-size: 15px;
        font-weight: 600;
        padding: 10px 20px;
        background-color: #2e6fa5;
        color: #ffffff;
        border: none;
        border-radius: 6px;
        cursor: pointer;
    }

    .container-contact .container-left .container-btn button:hover {
        background-color: #266497;
    }

    .container-right {
        width: 50%;
        height: auto;
        display: flex;
        justify-content: end;
        align-content: center;
    }

    .container-right img {
        width: 70%;
        height: auto;
        object-fit: contain;
    }
}